* {
  font-family: "Helvetica Neue", "Open Sans", Helvetica, Arial, sans-serif;
}

.hero_image {
  background-image: url("https://www.sunwestmortgage.com/swmc-images/Homepage/New/Sunwest-Hero-Banner-1920x1080_2008.jpg");
  height: 80vh;
  background-position: center -50px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 50vh;
}

.fineprint {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin: 3rem 0;
  color: #083f88;
}
.siteHeader {
  color: #0e3f7d;
  font-weight: 600;
}

.siteContents {
  color: #212529;
  line-height: 1.5;
  font-size: 1.2rem;
  margin-top: 1.5rem;
}

.siteContents a {
  text-decoration: none !important;
}

.promoImage {
  position: absolute;
  right: 0;
  top: 90px;
}

.btnRow {
  margin-top: 30px;
  position: absolute;
  top: 64%;
  right: 80px;
  text-align: center;
}

.button {
  z-index: 2;
  width: 250px;
  font-size: 24px;
  font-weight: 600;
  background: #00aae4;
  border-color: transparent;
  border: none !important;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 25px;
}

.testimonialsContainer {
  min-height: 350px;
}
.testimonialsContainerText {
  background-image: linear-gradient(rgb(0 15 34 / 51%), rgb(0 28 61 / 70%)),
    url("https://www.sunwestmortgage.com/swmc-images/adobe_image_swmc.jpeg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 10px;
  padding: 20px 20px 0px 20px;
}
.loanDivButtons {
  display: none;
}

@media (min-width: 320px) and (max-width: 360px) {
  .hero_image {
    min-height: 60vh !important;
    background-size: cover !important;
    background-position: top !important;
  }
  .button {
    width: 150px;
    font-size: 14px;
  }
  .promoImage {
    position: absolute;
    right: 0;
    top: 140px !important;
    width: 45px !important;
  }
}

@media (min-width: 361px) and (max-width: 450px) {
  .promoImage {
    position: absolute;
    right: 0;
    top: 150px !important;
    width: 50px;
  }
}

@media (min-width: 451px) and (max-width: 550px) {
  .promoImage {
    position: absolute;
    right: 0;
    top: 250px !important;
    width: 50px;
  }
}
@media (max-width: 992px) {
  .fineprint {
    text-align: start;
  }
}
@media (min-width: 361px) and (max-width: 380px) and (min-height: 800px) {
  .hero_image {
    min-height: 35vh !important;
    background-size: cover !important;
    background-position: top !important;
    height: 45vh !important;
  }

  .button {
    width: 170px;
    font-size: 16px;
  }
}

@media (min-width: 361px) and (max-width: 380px) and (max-height: 799px) {
  .hero_image {
    min-height: 54vh !important;
    background-size: cover !important;
    background-position: top !important;
    height: 54vh !important;
  }
  .button {
    width: 170px;
    font-size: 16px;
  }
}
@media (min-width: 381px) and (max-width: 450px) and (min-height: 800px) {
  .hero_image {
    min-height: 35vh !important;
    background-size: cover !important;
    background-position: top !important;
    height: 48vh !important;
  }

  .button {
    width: 170px;
    font-size: 16px;
  }
}

@media (min-width: 381px) and (max-width: 450px) and (max-height: 799px) {
  .hero_image {
    min-height: 54vh !important;
    background-size: cover !important;
    background-position: top !important;
  }

  .button {
    width: 170px;
    font-size: 16px;
  }
}
@media (min-width: 451px) and (max-width: 767px) {
  .hero_image {
    min-height: 65vh !important;
  }
  .promoImage {
    position: absolute;
    right: 0;
    top: 200px;
    width: 50px;
  }
}

@media (max-width: 767px) {
  .btnRow {
    top: 54%;
    left: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .testimonialsContainer {
    min-height: auto;
  }
  .testimonialsContainerText {
    width: 100%;
  }
  .loanDivButtons {
    display: block;
    background-color: white;
    border-color: white;
    color: #083f88;
    margin-bottom: 15px;
    border-radius: 20px;
  }

  .hero_image {
    background-image: url("https://www.sunwestmortgage.com/swmc-images/Homepage/New/newMobileHomeBanner.jpg");
    background-size: cover;
    position: relative;
    height: 50vh;
    min-height: 50vh;
    max-width: 1005;
    overflow: hidden;
    background-position: center 10%;
    margin-top: 75px;
  }

  .promoImage {
    position: absolute;
    right: 0;
    top: 90px;
    width: 60px;
  }
}

.mobile_Carousel {
  display: none;
}

.blogCards {
  box-shadow: 0 4px 20px 2px rgb(181 176 176 / 50%) !important;
  background-color: #fefefe !important;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 400px;
  margin: 0 auto;
}
.blogCards:hover {
  box-shadow: 0 4px 20px 2px rgb(181 176 176 / 30%) !important;
  transition: all 0.3s;
  border: none;
  background-color: white !important;
}
.blogCards > img {
  padding: 0px !important;
  transition: all 0.3s;
  max-height: 210px;
  height: auto;
  overflow: hidden;
}
.blogCards:hover > img {
  transform: scale(1.03);
  overflow: hidden;
  padding: 0px !important;
  transition: all 0.3s;
}
.blogCards:hover .blogCardBody {
  background-color: #083f88;
  transition: all 0.3s;
}
.blogCards:hover .blogCardTitle {
  background-color: #083f88;
  transition: all 0.3s;
}
.blogCards:hover .blogCardBodyText {
  color: white;
  transition: all 0.6s;
}
.blogCards:hover .blogCardTitle {
  color: white;
  transition: all 0.6s;
}
.blogCardTitle {
  font-size: 0.975rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  flex: 1 1 auto;
  display: flex;
  padding: 16px;
  align-items: center;
  margin-bottom: 0px;
}
.blogCardBody {
  padding: 16px;
}
.blogCardBodyText {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.54);
}
.modal {
  padding-top: 35vh;
}

.contentsection h5 {
  font-weight: 600;
  color: #6e6a6a;
}
.contentsection p {
  font-size: 15px;
  margin-bottom: 0px;
  color: #6e6a6a;
  max-width: 300px;
}
.contentsection {
  margin-top: 20px;
}
.headerRow {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 0px;
  width: fit-content;
  margin: 5px auto;
}
.header_border {
  margin-top: 25px;
  margin-left: 12px;
  height: 3px;
  background: #00aae4;
  width: 100px;
}
.homePageCards {
  box-shadow: 0px 4px 9px 0px #d2d2d2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
  padding: 10px 10px;
  min-height: 300px;
  text-align: center;
  border: 2px solid transparent;
  cursor: pointer;
}

.homePageCards:hover {
  box-shadow: 0px 2px 3px 0px #d2d2d2;
  border: 2px solid white;
  transition: all 0.3s linear;
  background-color: #083f88;
  border-radius: 6px;
}
.homePageCards:hover p {
  color: white;
}
.homePageCards:hover h5 {
  color: white;
}
.homePageCards:hover .imgsec {
  border: 2px solid white;
}
.homepageImg {
  width: 85px;
  padding: 10px;
}
.imgsec {
  border: 2px solid #00aae4;
  border-radius: 52px;
}
.loanSearchDiv {
  width: 70%;
  background-color: #fff;
  border-radius: 50px;
  padding: 15px 5px;
  position: absolute;
  top: -75px;
  left: 17%;
  box-shadow: 0px 0px 9px 4px rgba(0, 0, 0, 0.08);
}
.loanSearchField {
  width: 80%;
  border-radius: 20px;
}
.loanOfficerHeader {
  color: rgba(0, 0, 0, 0.54);
  text-align: left;
  font-weight: 600;
  position: relative;
  left: 5%;
  font-size: 17px;
}
.loanSearchButton {
  background: #00aae4 !important;
  border-color: #00aae4 !important;
}
.loanSearchButton:hover {
  background: #0069d9 !important;
  border-color: #0069d9 !important;
}
.loanSearchInnerDiv {
  display: flex;
  justify-content: center;
}
.cardViewMore p {
  color: #717070;
}
.cardViewMore img {
  margin-left: 6px;
  width: 11px;
}

.testimonialsCol {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 35px 0px 20px 0;
  color: black;
}
.contactButton {
  border-radius: 20px;
  color: #007bff;
  background: none;
  position: absolute;
  left: 30%;
}

.awardsImg {
  width: 100%;
}
.awardsImgdiv {
  width: 15%;
  margin: 15px;
}
.awardsName {
  text-align: center;
  justify-content: center;
  background-color: #b0aeae;
  padding: 10px 0;
}

.bottomBannerButton {
  padding: 8px 24px;
  border-radius: 0px 0px 6px 6px;
  border: 0;
  background: #0f5b7a;
  color: #fff;
  display: inline-block;
  font-size: 24px;
  font-family: inherit;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.bottomBannerButton:hover {
  background: #083648;
}
.fixedLenderButton {
  padding: 8px 16px;
  border-radius: 0px 0px 6px 6px;
  border: 0;
  background: #f29208;
  color: #fff;
  display: inline-block;
  font-size: 22px;
  font-family: inherit;
  cursor: pointer;
  position: absolute;
  right: -74px;
  transform: rotate(90deg);
}
.fixedLenderButton:hover {
  background: #d79231;
}

.getStartedButton {
  border-radius: 20px;
  background-color: #00aae4;
  border: 1px solid #00aae4;
}
.getStartedButton:hover {
  background-color: #0069d9;
}

.blogButton {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
}
.blogButton:hover {
  background-color: #0069d9 !important;
  border-color: #0069d9 !important;
}

.modal > div > div {
  padding: 0 20px;
}

.mappedBlog {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.mobilemappedBlog {
  margin: 0 auto;
  display: none;
}

.phoneNoContainer {
  min-height: 200px;
  background: #ececec;
  margin: 10px 0 0 0;
  padding: 30px;
  background-color: #083f88;
  color: #fff !important;
}
.phoneNoContainer a {
  color: #fff !important;
  font-weight: 600;
}

.phoneNoContainer h3 {
  font-weight: 600;
}

.subHead {
  text-align: center;
}
.callHeader {
  margin-top: 30px;
}

.phoneNoContainer .mailIcon {
  width: 15px;
  height: 16px;
  margin-top: -3px;
  margin-inline-start: 7px;
}
.homePageHorizontalCards {
  box-shadow: 0px 4px 9px 0px #d2d2d2;
  margin: 0 auto;
  display: flex;
  cursor: pointer;
  text-align: center;
}
.homePageHorizontalCards > .imgsec {
  border: none;
  width: 50%;
  display: flex;
}
.homePageHorizontalCards .homepageImg {
  padding: 0px;
  width: 100%;
}
.homePageHorizontalCards .contentsection {
  display: flex;
  width: 50%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.homePageHorizontalCards button {
  width: 50%;
}

.homePageHorizontalCards:hover {
  box-shadow: 0px 2px 3px 0px #d2d2d2;
  transition: all 0.3s linear;
  background-color: #083f88;
  border-radius: 6px;
}

.homePageHorizontalCards:hover p {
  color: white;
}
.homePageHorizontalCards:hover h5 {
  color: white;
}

@media (min-width: 320px) and (max-width: 991.7px) {
  .loanSearchDiv {
    position: absolute;
    margin: 0 auto;
    left: 0%;
    margin-top: -2vh;
    width: 100%;
    font-size: 12px;
    border-radius: 15px;
  }
  .homePageHorizontalCards button {
    width: 75%;
  }
  .loanSearchField {
    width: 100%;
    border-radius: 10px;
  }
  .loanOfficerHeader {
    color: rgba(0, 0, 0, 0.54);
    text-align: left;
    font-weight: 600;
    position: relative;
    left: 2%;
    font-size: 15px;
  }
  .blogCards {
    margin-top: 15px;
  }
  .loanSearchInput {
    margin-bottom: 5px;
  }
  .loanSearchButton {
    width: 50%;
    border-radius: 20px;
    margin-top: 10px;
  }
  .mobilemappedBlog {
    display: block;
  }
  .mappedBlog {
    display: none;
  }

  .header_border {
    margin-top: 50px;
  }
  .loanSearchInnerDiv {
    flex-direction: column;
    align-items: center;
  }
  .rowTop > div {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .headerRow {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 0px;
    width: 100%;
    margin: 5px auto;
  }
  .phoneNoContainer .subHead {
    margin-bottom: 20px;
  }

  .homePageCards:hover {
    box-shadow: 0px 4px 9px 0px #d2d2d2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    flex-direction: column;
    padding: 10px 10px;
    min-height: 300px;
    text-align: center;
    border: 2px solid transparent;
  }
  .homePageCards:hover p {
    color: #6e6a6a;
  }
  .homePageCards:hover h5 {
    color: #6e6a6a;
  }
  .homePageCards:hover .imgsec {
    border: 2px solid #00aae4;
  }
}

@media only screen and (min-width: 310px) and (max-width: 599px) {
  .loanSearchDiv {
    position: absolute;
    margin: 0 auto;
    left: 5%;
    width: 90%;
    font-size: 12px;
    border-radius: 15px;
  }
  .header_border {
    margin-top: 10px;
  }
  .header_border.whysunwestBorder {
    margin-top: -20px !important;
  }
  .headerRow {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 0px;
    width: 100%;
    margin: 5px auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .loanSearchDiv {
    width: 90%;
    left: 5%;
    padding: 10px;
  }
}
@media only screen and (min-width: 801px) and (max-width: 1083px) {
  .loanSearchDiv {
    width: 80%;
    left: 10%;
    padding: 15px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 499px) {
  .fixedLenderButton {
    padding: 8px 24px;
    font-size: 18px;
    right: -70.5px;
  }
  .linkViewMore {
    min-height: max-content;
  }

  .contactButton {
    left: 18px;
    margin-top: 20px;
  }
  .mobile_Carousel {
    display: block;
    margin-top: 60px;
  }
  .desktop_Carousel {
    display: none;
  }
}
@media (max-width: 600px) {
  .fineprint {
    font-size: 14px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 991.7px) {
  .linkViewMore {
    min-height: max-content;
  }
  .homePageCards {
    min-height: max-content !important;
  }
  .rowTop {
    justify-content: center;
    gap: 25px;
  }
  .rowTop > div {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .contactButton {
    left: 18px;
    margin-top: 20px;
  }
}

@media (min-width: 769px) and (max-width: 864px) {
  .hero_image {
    background-image: url("https://www.sunwestmortgage.com/swmc-images/Homepage/New/Sunwest-Hero-Banner-1920x1080_2008.jpg");
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 40vh !important;
  }
}

@media (min-width: 865px) and (max-width: 1023px) {
  .hero_image {
    background-image: url("https://www.sunwestmortgage.com/swmc-images/Homepage/New/Sunwest-Hero-Banner-1920x1080_2008.jpg");
    height: auto;
    background-position: center -30px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 40vh !important;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  .hero_image {
    height: auto;
    background-position: center -50px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 40vh !important;
  }
  .btnRow {
    margin-top: 0px;
    position: absolute;
    top: 76%;
    right: 15px;
    text-align: center;
  }
}

@media (min-width: 1025px) and (max-width: 1190px) {
  .hero_image {
    height: auto;
    background-position: center -50px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 50vh !important;
  }
  .btnRow {
    margin-top: 0px;
    position: absolute;
    top: 76%;
    right: 15px;
    text-align: center;
  }
}
@media (min-width: 1191px) and (max-width: 1320px) {
  .hero_image {
    height: auto;
    background-position: center -50px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 50vh !important;
  }
}
@media (min-width: 1024px) and (max-width: 1024px) and (min-height: 769px) {
  .hero_image {
    background-image: url("https://www.sunwestmortgage.com/swmc-images/Homepage/New/Sunwest-Hero-Banner-1920x1080_2008.jpg");
    height: 40vh;
    background-position: center -50px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 30vh;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) and (max-height: 767px) {
  .hero_image {
    height: 65vh;
    background-position: center -50px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 30vh;
  }
}

@media (min-width: 1400px) {
  .hero_image {
    height: 75vh;
    background-position: center -50px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .btnRow {
    right: 115px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .rowTop > div {
    width: 48% !important;
    max-width: 48% !important;
    min-width: 48% !important;
  }
  .rowTop {
    justify-content: center;
    gap: 20px 1%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1150px) {
  .rowTop > div {
    width: 48% !important;
    max-width: 48% !important;
    min-width: 48% !important;
  }
  .rowTop {
    justify-content: center;
    gap: 20px 1%;
  }
}

.serviceCards .Card {
  padding: 16px !important;
  height: 100% !important;
}
.serviceCards > a {
  display: inline-block;
  margin-bottom: 32px;
}
.calculatorContainer {
  padding: 30px 0;
  background-image: url("https://www.sunwestmortgage.com/swmc-images/Homepage/calculator.png");
  background-size: 87%;
  background-position-x: 100px;
  background-position-y: 19px;
  background-attachment: inherit;
}
@media (min-width: 1900px) {
  .calculatorContainer {
    width: 78vw;
    margin: 0 auto;
    min-width: 70vw;
    background-size: 74%;
  }
}
.loanCalculator {
  min-height: 100px;
  padding: 3% 10px;
  border: 1px solid #98daf0;
  border-radius: 5px;
  background-color: #083f88;
  padding: 5% 0;
  margin-bottom: 20px;
  opacity: 0.9;
}
.innerLoanBody {
  color: white;
  transition: all 0.3s linear;
}

.loanCalculator:hover {
  box-shadow: none;
  transition: all 0.3s linear;
  background-color: #083f88;
  border-radius: 6px;
  box-shadow: rgb(236, 229, 229) 7px 2px 21px 0px;
}
.loanCalculator:hover > .innerLoanBody {
  color: white;
  transition: all 0.3s linear;
}

.innerLoanBody {
  justify-content: center;
  align-items: center;
}

.calculatorContent {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.calculatorContent h2 {
  margin-bottom: 25px;
  font-size: 38px;
  font-weight: 600;
}
.calculatorContent p {
  font-size: 18px;
  line-height: 32px;
  max-width: 400px;
  font-weight: 400;
}
.calculatorContent a {
  display: inline-block;
  background-color: #00aae4;
  font-size: 14px;
  border-radius: 20px;
  padding: 6px 16px;
  color: #fff !important;
}

.calculatorContainer a:hover {
  background-color: #0069d9;
}

.blogContainer {
  background-image: url("https://www.sunwestmortgage.com/swmc-images/Homepage/New/Blog-min.jpg");
  background-size: cover;
  background-position: 0 -17px;
}

@media (max-width: 1920px) and (min-width: 1500px) {
  .blogContainer {
    background-position: 0 -40px;
  }
}

.blogContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 370px;
  color: white;
  padding: 16px;
  background: linear-gradient(
    360deg,
    rgba(38, 170, 225, 0.7) 20%,
    rgba(3, 67, 144, 0.7) 100%
  ) !important;
  margin: 0 auto;
}
.blogContent h2 {
  margin-bottom: 25px;
  font-size: 38px;
  font-weight: 600;
}
.blogContent p {
  font-size: 18px;
  line-height: 32px;
  max-width: 400px;
  font-weight: 400;
}
.blogContent a {
  display: inline-block;
  background-color: #0069d9;
  font-size: 14px;
  border-radius: 20px;
  padding: 6px 16px;
  color: #fff !important;
  text-decoration: underline;
}
.blogContent a:hover {
  background-color: #00aae4;
}
.getStartedDiv {
  padding: 0px;
  position: relative;
  margin: 30px 0;
  border-radius: 0px;
  background-image: url("https://www.sunwestmortgage.com/swmc-images/Homepage/New/ApplyNow.jpg");
  background-position: left;
  background-size: cover;
  background-attachment: fixed;
  margin-bottom: 0px;
  height: 350px;
  background-color: transparent;
  text-align: center;
}
.journeyCards {
  box-shadow: 1px 2px 10px 3px rgba(0, 0, 0, 0.275) !important;
  background-color: #083f88;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 10px;
  min-height: 300px;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 5px;
}

.journeyContent h5 {
  font-weight: 600;
  color: #000;
}
.journeyContent p {
  font-size: 15px;
  margin-bottom: 0px;
  color: #000;
  max-width: 300px;
}
.journeyContent {
  margin-top: 20px;
}
.journeyImgBorder {
  border: 2px solid white;
  border-radius: 52px;
}
.navPills {
  border: 2px solid #094088;
  border-radius: 50px;
}

@media (max-width: 992px) {
  .loanCalculator {
    min-height: 210px;
    margin-top: 30px;
  }
  .getStartedDiv {
    background-image: none;
  }
}
@media (min-width: 993px) AND (max-width: 1280px) {
  .loanCalculator {
    min-height: 220px;
  }
}

.applyNowBloak {
  padding: 50px;
  margin-top: 20px;
}
.applyNowBloak h2 {
  font-size: 42px;
  font-weight: 600;
}
.applyNowButton {
  width: 27% !important;
  padding: 11px;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  margin-top: 10px;
}

@font-face {
  font-family: "Lucky Fellas";
  src: url("../../fonts/LuckyFellas.woff2") format("woff2"),
    url("../../fonts/LuckyFellas.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.hero_image h1 {
  color: #fff;
  font-size: 56px;
  font-weight: bold;
  line-height: 0.9;
  z-index: 2;
  position: relative;
}
.hero_image h4 {
  color: #fff;
  line-height: 34px;
  letter-spacing: 1.152px;
  font-weight: 600;
}
.hero_image > div {
  padding: 0 50px;
}
.hero_image i {
  font-family: "Helvetica";
  font-size: 80px;
  color: #fff;
  font-weight: 600;
  color: #00aae4;
  font-display: swap;
}

@media (max-width: 992px) {
  .hero_image h1 {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    line-height: 0.9;
    z-index: 2;
    position: relative;
  }
  .hero_image h4 {
    color: #fff;
    line-height: 34px;
    letter-spacing: 1.152px;
    font-weight: 600;
  }
  .hero_image > div {
    padding: 0 20px;
  }
  .hero_image i {
    font-family: "Helvetica";
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    color: #00aae4;
    font-display: swap;
  }

  .applynowCard {
    width: 100vw !important;
    left: 0% !important;
    top: 0% !important;
  }
}

.pageBackground {
  background-image: url("https://www.sunwestmortgage.com/swmc-images/Homepage/calculators-background.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.applynowCard {
  background: #083f88;
  color: #f1f1f1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 40%;
  left: 30%;
  border-radius: 8px;
  top: 7%;
  position: absolute;
  opacity: 0.9;
}
.applynowCardButton {
  color: white !important;
  border: 1px solid white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  border-radius: 20px;
  width: 180px;
  margin-top: 5px;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.applynowCardButton:hover {
  background-color: white;
  color: #083f88 !important;
  text-decoration: none;
  border: 1px solid #083f88;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.newBlogCards {
  border-radius: 8px;
  background: #00aae4;
  padding: 30px;
  min-height: 350px;
  overflow: hidden;
  position: relative;
}
.blogLabel {
  background-color: #fff;
  padding: 0px 10px;
  border-radius: 0px 20px 20px 0px;
  font-style: italic;
  font-size: 16px;
  margin: 0;
  width: 60px;
  margin-bottom: 16px;
  color: #00aae4;
}
.newBlogTitle {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.newBlogCardBodyText {
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left !important;
  hyphens: auto !important;
}
.blogColumn {
  padding: 30px;
}
.blogRow {
  flex-direction: row-reverse;
}
@media (max-width: 767px) {
  .blogColumn {
    padding: 5px;
    margin-top: 10px;
  }
  .blogRow {
    flex-direction: column-reverse;
  }
  .circlewrapper::after {
    content: "";
    width: 2px;
    height: 70%;
    background: #083f88;
    display: inline-block;
    position: absolute;
    top: 78px;
    left: 47px;
    z-index: -1;
  }
}

.semi {
  height: 100px;
  width: 150px;
  border-radius: 150px 150px 0 0;
  border: 10px solid #00aae4;
  border-bottom: none;
  padding: 10px;
  box-sizing: content-box;
  padding: 25px;
}

.semi.sidedown {
  transform: rotate(180deg);
  margin-top: 90px;
  border: 10px solid #083f88;
  border-bottom: none;
}

.semi.sidedown .full {
  transform: rotate(180deg);
}

.full {
  height: 150px;
  width: 150px;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 50%;
  background: #98daf0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full img {
  width: 100%;
  background: transparent;
}

.containercircle {
  display: flex;
  padding-left: 12px;
  justify-content: center;
  margin-top: 20px;
}

.typosection {
  margin-top: 100px;
  text-align: center;
  margin-left: 5px;
}

.typosection.sidedowntypo {
  margin-top: 10px;
  text-align: center;
  margin-left: 15px;
}

.circlewrapper {
  margin-left: -10px;
  background: transparent;
  max-width: 219px;
  box-sizing: border-box;
  padding: 0px 10px;
  align-items: center;
  gap: 16px;
}

@media only screen and (max-width: 992px) {
  .containercircle {
    flex-direction: column;
    position: relative;
  }

  .typosection {
    margin-top: 0;
  }

  .circlewrapper {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .full {
    padding: 10px;
    height: 80px;
    width: 80px;
  }
  .semi,
  .semi.sidedown {
    height: 80px;
    width: 80px;
    border: 0px;
    padding: 0%;
    margin-bottom: 0;
  }

  .semi.sidedown {
    margin-top: 0px;
  }

  .containercircle > div:nth-child(even) {
    padding-top: 0px;
  }
}
