* {
  font-family: "Helvetica Neue", "Open Sans", Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: "Lucky Fellas";
  src: url("../../fonts/LuckyFellas.woff2") format("woff2"),
    url("../../fonts/LuckyFellas.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Soleil Semi Bold";
  src: url("../../fonts/Soleil-SemiBold.woff2") format("woff2"),
    url("../../fonts/Soleil-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.hero_image {
  /* background-image: url('./images/HomepageBanner.jpg'); */
  background-image: url("./images/approvedBanner.jpg");
  margin-top: 5rem;
  height: calc(80vh - 5rem);
  background-position: center -50px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 45vh;
}
.contentDiv {
  position: absolute;
  top: 30%;
}

.hero_image > div {
  padding: 0 30px;
}

.headerText .trueHome {
  font-family: "Lucky Fellas";
  font-size: 80px;
  font-weight: 500;
  color: #00aae4;
  line-height: 80px;
  letter-spacing: 0.012em;
  margin-top: 10px;
}

.btnRow {
  margin-top: 20px;
  text-align: center;
}

.button {
  z-index: 2;
  width: 250px;
  font-size: 24px;
  font-weight: 600;
  background: #00aae4;
  border-color: transparent;
  border: none !important;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 25px;
}

.bannerTextBlue {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 97.9974px;
  line-height: 106px;
  color: #00aae4;
}
.bannerTextNormal h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 76.2202px;
  line-height: 106px;
  color: #ffffff;
}
@media (min-width: 2160px) {
  .contentDiv {
    left: 15%;
  }
}
@media (min-width: 1301px) {
  .hero_image {
    min-height: auto !important;
    height: 600px !important;
    background-position: center right 35% !important;
  }
}

@media (min-width: 993px) and (max-width: 1300px) {
  .hero_image {
    min-height: auto !important;
    height: 500px !important;
    background-position: center right 35% !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .contentDiv {
    top: 35%;
  }
  .hero_image {
    min-height: auto !important;
    height: 500px !important;
    background-position: center right 30% !important;
  }

  .headerText .trueHome {
    font-size: 70px;
  }

  .button {
    width: 225px;
    font-size: 22px;
  }
}
@media screen and (max-width: 991px) {
  .bannerTextBlue {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 70px;
    color: #00aae4;
  }
  .bannerTextNormal h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 70px;
    color: #ffffff;
  }
}

@media (max-width: 992px) {
  .bannerTextBlue {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    color: #00aae4;
  }
  .bannerTextNormal h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 60px;
    color: #ffffff;
  }
  .btnRow {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .button {
    width: 220px;
    font-size: 18px;
  }

  .hero_image > div {
    padding: 0 20px;
  }
  .hero_image i {
    font-size: 40px;
  }
  .headerText .trueHome {
    font-size: 40px;
  }
}
@media screen and (max-width: 662px) {
  .bannerTextBlue {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    color: #00aae4;
  }
  .bannerTextNormal h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 50px;
    color: #ffffff;
  }
  .contentDiv {
    top: 45%;
  }
}
@media (min-width: 451px) and (max-width: 767px) {
  .hero_image {
    min-height: auto !important;
    height: 400px !important;
    background-position: center right 35% !important;
  }
}

@media (max-width: 450px) {
  .hero_image {
    margin-top: 4.9rem;
    min-height: auto !important;
    height: 600px !important;
    background-size: cover;
    background-image: url("./images/approvedBannerMobile.jpg");
    background-position: center !important;
  }

  .contentDiv {
    width: 100%;
    top: unset;
    bottom: 0%;
    padding: 0 1rem 1rem 1rem !important;
    text-align: center;
  }
  .bannerTextBlue {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    color: #00aae4;
  }
  .bannerTextNormal h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 60px;
    color: #ffffff;
  }
  .headerText .trueHome {
    font-size: 36px;
    line-height: 55px;
  }
  .btnRow {
    padding: 0;
    margin-top: 5px;
    justify-content: space-around;
    flex-direction: row !important;
  }
  .button {
    padding: 0.25rem 0.5rem;
    width: 175px !important;
    margin: 0.5rem auto;
  }
}
@media (max-width: 399.98px) {
  .contentDiv {
    padding: 0.5rem !important;
  }

  .headerText .trueHome {
    font-size: 32px;
    line-height: 35px;
  }
  .button {
    width: 140px !important;
  }
}

@media screen and (max-width: 355px) {
  .bannerTextBlue {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    color: #00aae4;
  }
  .bannerTextNormal h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 50px;
    color: #ffffff;
  }
  .hero_image {
    margin-top: 4.9rem;
    min-height: auto !important;
    height: 500px !important;
    background-size: cover;
    background-image: url("./images/approvedBannerMobile.jpg");
    background-position: center !important;
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .contentDiv {
    top: 35%;
  }

  .headerText .trueHome {
    font-size: 70px;
    line-height: 35px;
  }
  .btnRow {
    display: block;
  }
}
